import { default as _91_46_46_46slug_9321KQCtI9YwMeta } from "/app/app/pages/[...slug].vue?macro=true";
import { default as _2fa75IEBgFCR4Meta } from "/app/app/pages/auth/2fa.vue?macro=true";
import { default as forgot_45passwordiKPjrdoHLZMeta } from "/app/app/pages/auth/forgot-password.vue?macro=true";
import { default as loginJk0nDs6mH8Meta } from "/app/app/pages/auth/login.vue?macro=true";
import { default as registerHG2FfxYOj1Meta } from "/app/app/pages/auth/register.vue?macro=true";
import { default as _91_46_46_46token_93Arut4CPkH4Meta } from "/app/app/pages/auth/reset-password/[...token].vue?macro=true";
import { default as _91_46_46_46token_93i9eD4bNbJtMeta } from "/app/app/pages/auth/verify-email/[...token].vue?macro=true";
import { default as index0sHX7UThk5Meta } from "/app/app/pages/auth/verify-email/index.vue?macro=true";
import { default as _91_46_46_46slug_93NaaIhIsfjYMeta } from "/app/app/pages/collections/[...slug].vue?macro=true";
import { default as indexLz5gHWND6jMeta } from "/app/app/pages/collections/index.vue?macro=true";
import { default as indexpExt8PFnwnMeta } from "/app/app/pages/contribute/index.vue?macro=true";
import { default as scholar1zda6PDGyBMeta } from "/app/app/pages/contribute/scholar.vue?macro=true";
import { default as indexM6mJRhIbwTMeta } from "/app/app/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93HBad8bn7b1Meta } from "/app/app/pages/insights/[...slug].vue?macro=true";
import { default as indexB6HEuaslXEMeta } from "/app/app/pages/insights/index.vue?macro=true";
import { default as _91_46_46_46slug_93rbpdZl05g9Meta } from "/app/app/pages/institutions/[...slug].vue?macro=true";
import { default as indexfMAbdLQ4aAMeta } from "/app/app/pages/institutions/index.vue?macro=true";
import { default as leafletsS5jyVVsboMeta } from "/app/app/pages/leaflet.vue?macro=true";
import { default as oldsearchUeHtVsOd0QMeta } from "/app/app/pages/oldsearch.vue?macro=true";
import { default as _91_46_46_46slug_93vxwNTqBUP0Meta } from "/app/app/pages/ordering-knowledge/[...slug].vue?macro=true";
import { default as indexmMnUysiVhmMeta } from "/app/app/pages/ordering-knowledge/index.vue?macro=true";
import { default as _91slug_93aFyGKIW0PsMeta } from "/app/app/pages/portal/[slug].vue?macro=true";
import { default as indexZZ3OWQmNaVMeta } from "/app/app/pages/portal/index.vue?macro=true";
import { default as globallyPij2JlRfZZMeta } from "/app/app/pages/protected/globally.vue?macro=true";
import { default as _91_46_46_46slug_93y5Q50DiqNyMeta } from "/app/app/pages/publications/[...slug].vue?macro=true";
import { default as index1IwkUVsKPhMeta } from "/app/app/pages/publications/index.vue?macro=true";
import { default as _91_46_46_46slug_93Jmlln2Dbi6Meta } from "/app/app/pages/scholars/[...slug].vue?macro=true";
import { default as indexcIUKPYjvOwMeta } from "/app/app/pages/scholars/index.vue?macro=true";
import { default as searchwyeWPnHEGGMeta } from "/app/app/pages/search.vue?macro=true";
import { default as _91_46_46_46slug_93yT2cSf5bjaMeta } from "/app/app/pages/series/[...slug].vue?macro=true";
import { default as indexEUkOqUGpcVMeta } from "/app/app/pages/series/index.vue?macro=true";
import { default as _91shorturl_9346Mp8sKGJSMeta } from "/app/app/pages/share/[shorturl].vue?macro=true";
import { default as testpageZJJ5G4l7mGMeta } from "/app/app/pages/testpage.vue?macro=true";
import { default as editCrEFHLy6hkMeta } from "/app/app/pages/user/edit.vue?macro=true";
import { default as indexwQs8TzbCD0Meta } from "/app/app/pages/user/index.vue?macro=true";
import { default as _91_46_46_46slug_93LLnK7lhNUQMeta } from "/app/app/pages/v2/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_930klRyB1jINMeta } from "/app/app/pages/v2/scholars/[...slug].vue?macro=true";
import { default as searchhqAF4qpQlJMeta } from "/app/app/pages/v2/search.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_9321KQCtI9YwMeta || {},
    component: () => import("/app/app/pages/[...slug].vue")
  },
  {
    name: "auth-2fa",
    path: "/auth/2fa",
    component: () => import("/app/app/pages/auth/2fa.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordiKPjrdoHLZMeta || {},
    component: () => import("/app/app/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginJk0nDs6mH8Meta || {},
    component: () => import("/app/app/pages/auth/login.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerHG2FfxYOj1Meta || {},
    component: () => import("/app/app/pages/auth/register.vue")
  },
  {
    name: "auth-reset-password-token",
    path: "/auth/reset-password/:token(.*)*",
    component: () => import("/app/app/pages/auth/reset-password/[...token].vue")
  },
  {
    name: "auth-verify-email-token",
    path: "/auth/verify-email/:token(.*)*",
    component: () => import("/app/app/pages/auth/verify-email/[...token].vue")
  },
  {
    name: "auth-verify-email",
    path: "/auth/verify-email",
    meta: index0sHX7UThk5Meta || {},
    component: () => import("/app/app/pages/auth/verify-email/index.vue")
  },
  {
    name: "collections-slug",
    path: "/collections/:slug(.*)*",
    component: () => import("/app/app/pages/collections/[...slug].vue")
  },
  {
    name: "collections",
    path: "/collections",
    meta: indexLz5gHWND6jMeta || {},
    component: () => import("/app/app/pages/collections/index.vue")
  },
  {
    name: "contribute",
    path: "/contribute",
    meta: indexpExt8PFnwnMeta || {},
    component: () => import("/app/app/pages/contribute/index.vue")
  },
  {
    name: "contribute-scholar",
    path: "/contribute/scholar",
    component: () => import("/app/app/pages/contribute/scholar.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexM6mJRhIbwTMeta || {},
    component: () => import("/app/app/pages/index.vue")
  },
  {
    name: "insights-slug",
    path: "/insights/:slug(.*)*",
    meta: _91_46_46_46slug_93HBad8bn7b1Meta || {},
    component: () => import("/app/app/pages/insights/[...slug].vue")
  },
  {
    name: "insights",
    path: "/insights",
    component: () => import("/app/app/pages/insights/index.vue")
  },
  {
    name: "institutions-slug",
    path: "/institutions/:slug(.*)*",
    meta: _91_46_46_46slug_93rbpdZl05g9Meta || {},
    component: () => import("/app/app/pages/institutions/[...slug].vue")
  },
  {
    name: "institutions",
    path: "/institutions",
    component: () => import("/app/app/pages/institutions/index.vue")
  },
  {
    name: "leaflet",
    path: "/leaflet",
    component: () => import("/app/app/pages/leaflet.vue")
  },
  {
    name: "oldsearch",
    path: "/oldsearch",
    component: () => import("/app/app/pages/oldsearch.vue")
  },
  {
    name: "ordering-knowledge-slug",
    path: "/ordering-knowledge/:slug(.*)*",
    meta: _91_46_46_46slug_93vxwNTqBUP0Meta || {},
    component: () => import("/app/app/pages/ordering-knowledge/[...slug].vue")
  },
  {
    name: "ordering-knowledge",
    path: "/ordering-knowledge",
    component: () => import("/app/app/pages/ordering-knowledge/index.vue")
  },
  {
    name: "portal-slug",
    path: "/portal/:slug()",
    component: () => import("/app/app/pages/portal/[slug].vue")
  },
  {
    name: "portal",
    path: "/portal",
    component: () => import("/app/app/pages/portal/index.vue")
  },
  {
    name: "protected-globally",
    path: "/protected/globally",
    component: () => import("/app/app/pages/protected/globally.vue")
  },
  {
    name: "publications-slug",
    path: "/publications/:slug(.*)*",
    meta: _91_46_46_46slug_93y5Q50DiqNyMeta || {},
    component: () => import("/app/app/pages/publications/[...slug].vue")
  },
  {
    name: "publications",
    path: "/publications",
    component: () => import("/app/app/pages/publications/index.vue")
  },
  {
    name: "scholars-slug",
    path: "/scholars/:slug(.*)*",
    meta: _91_46_46_46slug_93Jmlln2Dbi6Meta || {},
    component: () => import("/app/app/pages/scholars/[...slug].vue")
  },
  {
    name: "scholars",
    path: "/scholars",
    component: () => import("/app/app/pages/scholars/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: searchwyeWPnHEGGMeta || {},
    component: () => import("/app/app/pages/search.vue")
  },
  {
    name: "series-slug",
    path: "/series/:slug(.*)*",
    meta: _91_46_46_46slug_93yT2cSf5bjaMeta || {},
    component: () => import("/app/app/pages/series/[...slug].vue")
  },
  {
    name: "series",
    path: "/series",
    component: () => import("/app/app/pages/series/index.vue")
  },
  {
    name: "share-shorturl",
    path: "/share/:shorturl()",
    component: () => import("/app/app/pages/share/[shorturl].vue")
  },
  {
    name: "testpage",
    path: "/testpage",
    component: () => import("/app/app/pages/testpage.vue")
  },
  {
    name: "user-edit",
    path: "/user/edit",
    meta: editCrEFHLy6hkMeta || {},
    component: () => import("/app/app/pages/user/edit.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: indexwQs8TzbCD0Meta || {},
    component: () => import("/app/app/pages/user/index.vue")
  },
  {
    name: "v2-slug",
    path: "/v2/:slug(.*)*",
    meta: _91_46_46_46slug_93LLnK7lhNUQMeta || {},
    component: () => import("/app/app/pages/v2/[...slug].vue")
  },
  {
    name: "v2-scholars-slug",
    path: "/v2/scholars/:slug(.*)*",
    meta: _91_46_46_46slug_930klRyB1jINMeta || {},
    component: () => import("/app/app/pages/v2/scholars/[...slug].vue")
  },
  {
    name: "v2-search",
    path: "/v2/search",
    meta: searchhqAF4qpQlJMeta || {},
    component: () => import("/app/app/pages/v2/search.vue")
  }
]