<script setup lang="ts">
import { WidgetsFloatingPlayer } from '#components'
import { useRefHistory } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { ModalsContainer } from 'vue-final-modal'
import { useMediaStore } from '~~/stores/media'

import { useSearchStore } from '~~/stores/search'
import { useSiteStore } from '~~/stores/site'
import { Toaster } from '~/components/ui/sonner'
import '@fontsource/alegreya/400.css'
import '@fontsource/alegreya/600.css'
import '@fontsource/alegreya/700.css'

const siteStore = useSiteStore()
const searchStore = useSearchStore()

const mediaStore = useMediaStore()
const { isVideoModalOpen } = storeToRefs(mediaStore)

const nuxtApp = useNuxtApp()
const config = useAppConfig()
const isLoading = ref(false)

nuxtApp.hook('page:start', () => {
  isLoading.value = true
})
nuxtApp.hook('page:finish', () => {
  isLoading.value = false
})
onMounted(() => {
  // eslint-disable-next-line no-console
  console.info(
    `🚀 CrossArea Frontend 🔢 Version: ${config.app.version}`,
    `🕙 BuildDate: ${new Date(config.app.buildtime).toLocaleString()}`,
  )
})

useSchemaOrg([
  defineWebSite({
    name: 'CrossArea - Knowledge Portal',
  }),
  defineWebPage(),
])
</script>

<template>
  <div>
    <NuxtRouteAnnouncer />
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <CookieControl locale="en" />
    <WidgetsFloatingPlayer />
    <Toaster />
  </div>
</template>

<style scoped>
/* You can customise the default animation here. */

::view-transition-old(root) {
  animation: 90ms cubic-bezier(0.4, 0, 1, 1) both fade-out;
}

::view-transition-new(root) {
  animation: 210ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in;
}
</style>

<style>
.cookieControl__ControlButton {
  bottom: 5rem;
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}

.layout-enter-from,
.layout-leave-to {
  filter: grayscale(1);
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
